import Head from 'next/head';

const Seo = ({ pageTitle }) => (
  <>
    <Head>
      <title>{`Origins`}</title>
      <link rel='icon' href='../public/images/origins-logo.png' />
      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
    </Head>
  </>
);

export default Seo;
