import dynamic from 'next/dynamic';
import Seo from '../components/common/Seo';

const Home9 = dynamic(() => import('../components/home-9'), {
  ssr: false,
  loading: () => <p>Loading...</p>, // Replace with your actual loading component
});

const index = () => {
  return (
    <>
      <Seo pageTitle='Home' />
      <Home9 />
    </>
  );
};

export default index;
